<template>
    <layout-default size="medium">
        <div class="back">
            <router-link to="/app/wallet"><i class="material-icons">chevron_left</i> Back to Wallet</router-link>
        </div>
        <div class="card account-email">
            <div class="card-title">
                <h2>KYC</h2>
            </div>
            <div v-if="user.kyc" class="card-content">
                <p>
                    Your KYC status is <strong>completed</strong>, you can contribute to Blockwell.
                </p>
                <p>
                    <router-link to="/app/account/kyc" class="btn btn-primary">KYC Information</router-link>
                </p>
            </div>
            <div v-else class="card-content">
                <p>
                    Your KYC status is <strong>incomplete</strong>. You will need to complete your KYC before
                    contributing to Blockwell.
                </p>
                <p>
                    <router-link to="/app/account/kyc" class="btn btn-primary">Complete KYC</router-link>
                </p>
            </div>
        </div>
        <div class="card account-email">
            <div class="card-title">
                <h2>Email Address</h2>
            </div>
            <div class="card-content">
                <p>
                    {{ user.email }}
                </p>
                <p v-if="user.emailVerified">
                    Your email address has been verified.
                </p>
                <div v-else>
                    <p>
                        Your email address has not been verified. Email verification is required for verifying external
                        wallets and for KYC.
                    </p>
                    <p>
                        <VerifyEmail>Verify Email</VerifyEmail>
                    </p>
                </div>
            </div>
        </div>
        <div class="card account-wallets">
            <div class="card-title">
                <h2>External Wallets</h2>
            </div>
            <div class="card-content">
                <p>
                    External wallets are wallets you control outside of the Blockwell system. You can verify that those
                    wallets are yours using External Wallet Verification.
                </p>
                <p v-if="user.externalWallets">
                    You've verified {{ user.externalWallets }} external wallet{{ user.externalWallets > 1 ? "s" : "" }}.
                </p>
                <p v-else>
                    You have no verified external wallets.
                </p>
                <p>
                    <router-link to="/app/account/external"
                                 class="btn btn-primary">Manage External Wallets</router-link>
                </p>
            </div>
        </div>
        <div class="card account-wallets">
            <div class="card-title">
                <h2>Emojicryption Settings</h2>
            </div>
            <div class="card-content">
                <p>
                    Manage your emojicryption settings for the <router-link to="/qc">Quick Cipher</router-link> page.
                </p>
                <p>
                    <router-link to="/app/account/emojicryption"
                                 class="btn btn-primary">Emojicryption Settings</router-link>
                </p>
            </div>
        </div>
        <div class="card account-wallets">
            <div class="card-title">
                <h2>API Miner Access</h2>
            </div>
            <div class="card-content">
                <p>
                    As a Blockwell user you can gain access to
                    <a href="https://docs.blockwell.ai/apiminer" target="_blank">API Miner</a>
                    .
                </p>
                <p>
                    <router-link to="/app/account/apiminer" class="btn btn-primary">Manage API Keys</router-link>
                </p>
            </div>
        </div>
        <div class="card referral">
            <div class="card-title">
                <h2>Referral ID</h2>
            </div>
            <p>Your referral ID is {{referralId}}. Use the following link for referrals:</p>
            <p>
                <Clipboard v-if="referralLink" :clipboard="referralLink" :link="referralLink" />
                <span v-else class="error">Invalid link, you can only use app.blockwell.ai links.</span>
            </p>
            <FormulateInput
                type="text"
                label="Modify Link"
                help="Paste in the app.blockwell.ai link you want to add your referral ID to."
                v-model="link"
                />
        </div>
    </layout-default>
</template>

<script>
import Clipboard from "@/components/Clipboard";
import VerifyEmail from "@/components/VerifyEmail";
import {mapGetters} from "vuex";

export default {
    components: {
        Clipboard,
        VerifyEmail
    },
    data() {
        return {
            user: {},
            link: window.location.protocol + "//" + window.location.host
        };
    },
    created() {
        this.fetchData();
    },
    computed: {
        ...mapGetters('user', ['api']),
        referralLink() {
            let url;
            try {
                url = new URL(this.link, window.location.protocol + "//" + window.location.host);
                url?.searchParams.set('1r', this.referralId);
            } catch (err) {
                console.warn(err.message);
            }

            if (!url || url.host !== window.location.host) {
                return null;
            }

            return url.toString();
        }
    },
    asyncComputed: {
        referralId() {
            return this.api.getReferralId();
        }
    },
    methods: {
        async fetchData() {
            this.user = await this.api.getMe();
        }
    }
}
</script>
