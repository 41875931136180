<template>
    <layout-default size="medium">
        <div class="back">
            <router-link v-if="kyc" to="/app/account/kyc"><i class="material-icons">chevron_left</i> Back to KYC
            </router-link>
            <router-link v-else to="/app/account"><i class="material-icons">chevron_left</i> Back to Account
            </router-link>
        </div>
        <div class="card">
            <div class="card-title">
                <h3>External Wallets</h3>
            </div>
            <div class="card-content">
                <Markdown v-if="description" :content="description"/>
                <template v-else>
                    <p>
                        External wallets are wallets you have access to that are outside Blockwell.
                        This could be Ledger, Trezor, Metamask or others.
                    </p>
                    <p>
                        You can add external wallets to your account to verify that you have access
                        to that wallet.
                        <span v-if="kyc">
                            To complete KYC, you must verify at least one external wallet.
                        </span>
                    </p>
                </template>
                <table v-if="accounts.length > 0" class="table table-responsive-sm external-table">
                    <thead>
                    <tr>
                        <th>
                            Name
                        </th>
                        <th>
                            Status
                        </th>
                        <th>
                            Your Wallet Address
                        </th>
                        <th>
                            Send bwKYC To Wallet
                        </th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                    <tr v-for="account in accounts" class="external-row" :data-external="account.address"
                        :data-status="account.status">
                        <td class="external-name">
                            {{account.name}}
                        </td>
                        <td class="external-status">
                            <template v-if="account.status === 'pending'">
                                Pending Verification
                                <br>
                                <a href="javascript: void 0;" class="view-instructions"
                                   @click="showInstructions(account)">view instructions</a>
                            </template>
                            <template v-else>
                                Verified
                            </template>
                        </td>
                        <td class="external-address">
                            <Clipboard :clipboard="account.address"/>
                        </td>
                        <td class="to-address">
                            <Clipboard :clipboard="account.verifyAddress"/>
                        </td>
                    </tr>
                    </tbody>
                </table>

                <div class="form-group new-external-big">
                    <button class="btn btn-primary new-external" type="button">Add External Wallet
                    </button>
                    <a href="javascript: void 0;" class="find-address">How do I find my wallet
                        address?
                    </a>
                </div>
                <div class="form-group kyc-wallet-verified" style="display: none;">
                    <strong>You have successfully verified a wallet for KYC</strong>.
                    <router-link to="/app/account/kyc">Click Here</router-link>
                    to return to the KYC process, or you can
                    <a href="javascript: void 0;" class="new-external">Add Another External Wallet</a>.
                </div>
            </div>
        </div>
        <div class="modal fade" id="new-external-modal" tabindex="-1" role="dialog" aria-hidden="true">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">New External Wallet</h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <p>
                            Fill in the Address of your wallet. You will need to verify that you own the wallet, so it
                            has to be a wallet you have access to and can use to send a token.
                        </p>
                        <p>
                            Entering a Name is optional, it's just for your own reference when viewing the list of
                            wallets you've added.
                        </p>
                        <form id="new-external" ref="form">
                            <div class="form-group row no-gutters">
                                <label class="col-12">Name</label>
                                <div class="col-md-5">
                                    <input type="text" class="form-control" name="name">
                                </div>
                                <div class="invalid-feedback">
                                </div>
                                <small class="form-text text-muted col-12">
                                    Optional. Enter a name for this wallet for your own reference.
                                </small>
                            </div>
                            <div class="form-group row no-gutters">
                                <label class="col-12">Wallet Address</label>
                                <div class="col-md-12">
                                    <input type="text" class="form-control" name="address">
                                </div>
                                <div class="invalid-feedback">
                                </div>
                                <small class="form-text text-muted col-12">
                                    Enter the address of the external wallet.
                                </small>
                            </div>

                            <p>
                                <a href="javascript: void 0;" class="find-address">How do I find my Wallet Address?</a>
                            </p>

                            <p>
                                <strong>This does NOT give us or anyone else access to that wallet.</strong> It simply
                                proves you own that wallet.
                            </p>
                            <div class="error form-error"></div>
                            <div class="form-group">
                                <button class="btn btn-primary" type="submit">Submit</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
        <ExternalInstructions/>
        <ExternalFindWallet/>
    </layout-default>
</template>

<script>
    import Clipboard from "@/components/Clipboard";
    import Markdown from "@/components/Markdown";
    import ExternalFindWallet from "@/views/app/account/ExternalFindWallet";
    import ExternalInstructions from "@/views/app/account/ExternalInstructions";
    import * as ClipboardJS from "clipboard";
    import * as util from '@/assets/lib/util';
    import Api from '@/lib/api/WalletApi';

    const api = new Api('legacy');
    let token;
    let timeout;

    export default {
        components: {Markdown, Clipboard, ExternalFindWallet, ExternalInstructions},
        props: {
            kyc: {
                type: Boolean,
                default: false
            }
        },
        data() {
            return {
                description: null,
                accounts: []
            };
        },
        mounted() {
            this.refresh();

            api.getTokenData(process.env.VUE_APP_VERIFICATION_TOKEN)
                .then(res => {
                    token = res;
                });

            api.getExternalDescription()
                .then(res => {
                    this.description = res.description;
                });

            $('.new-external').click(function (ev) {
                ev.preventDefault();

                $('.modal').modal('hide');

                let $modal = $('#new-external-modal');
                $modal.find('input').val('');
                $modal.find('button[type=submit]').attr('disabled', false);
                $modal.modal({
                    backdrop: 'static'
                });
            });

            $('#new-external').on('submit', (ev) => {
                ev.preventDefault();
                let $modal = $('#new-external-modal');
                $modal.find('.form-error').text('');
                const $submit = $modal.find('button[type=submit]');
                $submit.attr('disabled', true);
                let data = util.getFormData(this.$refs.form);

                if (/^0x[a-fA-F0-9]{40}$/.test(data.address)) {
                    api.newExternalAccount(data.address, data.name)
                        .then(ext => {
                            $modal.modal('hide');
                            this.showInstructions(ext);
                            this.refresh();
                        })
                        .catch(err => {
                            $modal.find('.form-error').html(util.errorText(err));
                            $submit.attr('disabled', false);
                        });
                } else {
                    $modal.find('.form-error').text('Please enter a valid wallet address.');
                    $submit.attr('disabled', false);
                }

                return false;
            });

            $('.wallet-selection .wallet-type').click(async function () {
                let wallet = $(this).data('wallet');
                let content = $(this).parents('.wallet-selection-container').find(`.wallet-selection-content[data-wallet=${wallet}]`);
                await util.fadeOut(
                    $(this).parents('.wallet-selection-container').find('.wallet-selection-wrap')
                );
                await util.fadeIn(content);
            });

            $('.back-to-selection').click(async function () {
                await util.fadeOut(
                    $(this).parents('.wallet-selection-container').find(`.wallet-selection-content`)
                );
                await util.fadeIn(
                    $(this).parents('.wallet-selection-container').find('.wallet-selection-wrap')
                );
            });

            $('.find-address').click(() => {
                this.showAddressInstructions();
            });
        },
        destroyed() {
            if (timeout) {
                clearTimeout(timeout);
                timeout = null;
            }
        },
        methods: {
            refresh() {
                api.getExternalAccounts()
                    .then(accounts => {
                        this.accounts = accounts;

                        if (accounts.find(it => it.status !== "verified")) {
                            if (!timeout) {
                                timeout = setTimeout(() => this.refresh(), 5000);
                            }
                        }
                        if (accounts.find(it => it.status === "verified") && this.kyc) {
                            $('.new-external-big').hide();
                            $('.kyc-wallet-verified').show();
                        }
                    });
            },

            showInstructions(ext) {
                $('.modal').modal('hide');

                let $modal = $('#instructions-modal');

                $modal.find('.wallet-selection-content').hide();
                $modal.find('.wallet-selection-wrap').show();

                $modal.find('.token-address').html(util.renderAddress(token.address, token.network));
                $modal.find('.token-symbol').text(token.symbol);
                $modal.find('.token-decimals').text(token.decimals);
                $modal.find('.verify-address').html(util.renderAddress(ext.verifyAddress));
                $modal.find('.wallet-address').html(ext.address);

                let clip = new ClipboardJS('#instructions-modal .clipboard', {
                    container: $('#instructions-modal .modal-content')[0]
                });
                clip.on('success', (e) => {
                    e.clearSelection();
                });

                $modal.modal();
            },

            showAddressInstructions() {
                $('.modal').modal('hide');

                let $modal = $('#find-address-modal');

                $modal.find('.wallet-selection-content').hide();
                $modal.find('.wallet-selection-wrap').show();

                $modal.modal();
            }
        }
    }


</script>

<style lang="scss">
    .external-table .clipboard-text {
        max-width: 140px;
        display: inline-block;
        vertical-align: middle;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
</style>
