<template>
    <div class="modal fade" id="instructions-modal" tabindex="-1" role="dialog" aria-hidden="true">
        <div class="modal-dialog modal-lg modal-dialog-scrollable" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">Verification Instructions</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <p>
                        To finish adding an external wallet, you must verify that you have access to it. This is
                        done using a special verification token that you can send using your existing wallet
                        software.
                    </p>
                    <p>
                        <strong>Note! You MUST send the token from the wallet you're verifying</strong>: <span
                        class="wallet-address"></span>
                    </p>
                    <p>
                        How you send the token depends on the wallet software you use.
                    </p>

                    <div class="wallet-selection-container">
                        <div class="wallet-selection-wrap">
                            <p>Select your wallet.</p>
                            <div class="wallet-selection">
                                <div class="wallet-type-wrap">
                                    <div class="wallet-type" data-wallet="trezor">
                                        <img src="../../../assets/images/logos/trezor.png" alt="Trezor">
                                    </div>
                                </div>
                                <div class="wallet-type-wrap">
                                    <div class="wallet-type" data-wallet="ledger">
                                        <img src="../../../assets/images/logos/ledger.svg" alt="Ledger">
                                    </div>
                                </div>
                                <div class="wallet-type-wrap">
                                    <div class="wallet-type" data-wallet="metamask">
                                        <img src="../../../assets/images/logos/metamask.png" alt="MetaMask">
                                    </div>
                                </div>
                                <div class="wallet-type-wrap">
                                    <div class="wallet-type" data-wallet="mew">
                                        <img src="../../../assets/images/logos/mew.png" alt="MyEtherWallet">
                                    </div>
                                </div>
                                <div class="wallet-type-wrap">
                                    <div class="wallet-type" data-wallet="mycrypto">
                                        <img src="../../../assets/images/logos/mycrypto.svg" alt="MyCrypto">
                                    </div>
                                </div>
                                <div class="wallet-type-wrap">
                                    <div class="wallet-type" data-wallet="other">
                                        <div class="wallet-name">Other</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="wallet-selection-content" data-wallet="trezor">
                            <a href="javascript: void 0;" class="back-to-selection">back to wallet selection</a>
                            <h3>Trezor</h3>
                            <p>
                                Trezor's own software does not support ERC20 tokens, but they have a partnership
                                with MyEtherWallet that lets you use your Trezor for any token.
                            </p>
                            <p>
                                If you already have MyEtherWallet connected to Trezor, you can skip to Step 9.
                            </p>
                            <h4>1. Plug Trezor In To Your Computer</h4>
                            <p>
                                Plug Trezor into your computer using its USB cable.
                            </p>
                            <h4>2. Open MyEtherWallet</h4>
                            <p>
                                Open MyEtherWallet by going to
                                <a href="https://www.myetherwallet.com/access-my-wallet" target="_blank">
                                    myetherwallet.com/access-my-wallet
                                </a>
                            </p>
                            <h4>3. Access Hardware Wallet</h4>
                            <p>
                                Select the "Hardware" option for access.
                            </p>
                            <p class="text-center">
                                <img src="../../../assets/images/screenshots/mew-hardware.png" width="150" alt="">
                            </p>
                            <h4>4. Select Trezor</h4>
                            <p>In the list select Trezor and press "Continue".</p>
                            <h4>5. Install Bridge If Asked</h4>
                            <p>
                                At this point Trezor may ask you to install the Trezor Bridge. Follow their
                                instructions, and once installed go back to Step 1.
                            </p>
                            <h4>6. Allow MyEtherWallet To Read</h4>
                            <p>
                                Trezor will ask you to give permission for MyEtherWallet to "Read public keys from
                                Trezor device". Click "Allow once for this session", or check the "Don't ask me
                                again" checkbox and then click "Always allow for this service".
                            </p>
                            <h4>7. Export Ethereum public key</h4>
                            <p>
                                Next, Trezor will ask you to Export your Ethereum public key. This allows
                                MyEtherWallet to see your wallet address.
                            </p>
                            <h4>8. Select Network and Address</h4>
                            <p>
                                First make sure at the top it says "ETH" for the Network, like this:
                            </p>
                            <p class="text-center">
                                <img src="../../../assets/images/screenshots/mew-eth-network.png"
                                     alt="MEW screenshot">
                            </p>
                            <p>
                                In the list, select the address <span
                                class="wallet-address"></span>.
                            </p>
                            <p><strong>Note!</strong> You will need a small amount of Ether in your wallet. Make
                                sure you have 0.01 ETH or more.</p>
                            <p>
                                Check that you accept the terms, and press "Access My Wallet".
                            </p>
                            <h4>9. Add Custom ERC20 Token</h4>
                            <p>
                                Find the Tokens list box on the page, and click "+ Custom Tokens":
                            </p>
                            <p class="text-center">
                                <img src="../../../assets/images/screenshots/mew-custom-token.png">
                            </p>
                            <p>
                                Add a custom token with the address <span class="token-address"></span>, symbol
                                "<span
                                class="token-symbol"></span>" and decimals <span class="token-decimals"></span>.
                            </p>
                            <p class="text-center">
                                <img src="../../../assets/images/screenshots/mew-custom-token-form.png" alt="">
                            </p>
                            <p>Click "Save".</p>
                            <h4>10. Send Transaction</h4>
                            <p>
                                Click the "Send Transaction" button on the same page:
                            </p>
                            <p class="text-center">
                                <img src="../../../assets/images/screenshots/mew-send-transaction.png" width="180"
                                     alt="">
                            </p>
                            <p>In the form that opens:</p>
                            <ul>
                                <li>Select "<span class="token-symbol"></span>" for Type</li>
                                <li>Enter "1" for the Amount</li>
                                <li>In the To Address, paste the following address: <span
                                    class="verify-address"></span>
                                </li>
                            </ul>
                            <p>
                                If you see an error saying "Not enough Gas to send", that means your wallet doesn't
                                have enough Ether to pay for the transaction. Make sure your wallet has at least
                                0.01 ETH.
                            </p>
                            <p>
                                Click "Send Transaction".
                            </p>
                            <h4>11. Allow Transaction</h4>
                            <p>
                                Trezor will ask you to allow MyEtherWallet to "Prepare Trezor device for transaction
                                and data signing". Click "Allow".
                            </p>
                            <h4>12. Confirm Sending</h4>
                            <p>
                                Your Trezor device will now ask you to Confirm sending the tokens. Accept the
                                sending.
                            </p>
                            <h4>13. Confirm In MyEtherWallet</h4>
                            <p>
                                Lastly, MyEtherWallet will ask you to confirm the transfer of <span
                                class="token-symbol"></span>. Click "Confirm and Send".
                            </p>
                            <p><strong>Done!</strong> You will receive an email confirmation shortly after sending.
                            </p>
                        </div>
                        <div class="wallet-selection-content" data-wallet="ledger">
                            <a href="javascript: void 0;" class="back-to-selection">back to wallet selection</a>
                            <h3>Ledger</h3>
                            <p>
                                Ledger's own software does not support ERC20 tokens, but they have a partnership
                                with MyEtherWallet that lets you use your Ledger for any token.
                            </p>
                            <p>
                                If you already have MyEtherWallet connected to Ledger, you can skip to Step 7.
                            </p>
                            <h4>1. Plug Ledger In To Your Computer</h4>
                            <p>
                                Plug your Ledger in to your computer using its USB cable, unlock it, and open the
                                Ethereum app.
                            </p>
                            <h4>2. Open MyEtherWallet</h4>
                            <p>
                                Open MyEtherWallet by going to
                                <a href="https://www.myetherwallet.com/access-my-wallet" target="_blank">
                                    myetherwallet.com/access-my-wallet
                                </a>
                            </p>
                            <h4>3. Access Hardware Wallet</h4>
                            <p>
                                Select the "Hardware" option for access.
                            </p>
                            <p class="text-center">
                                <img src="../../../assets/images/screenshots/mew-hardware.png" width="150" alt="">
                            </p>
                            <h4>4. Select Ledger</h4>
                            <p>In the list select Ledger and press "Continue".</p>
                            <h4>5. Choose Ledger App</h4>
                            <p>
                                Choose which Ledger app you normally use. If you usually use Ledger Live, select
                                that. Otherwise, keep the selection on just "Ethereum".
                            </p>
                            <p class="text-center">
                                <img src="../../../assets/images/screenshots/mew-choose-app.png" width="400" alt="">
                            </p>
                            <p>
                                Then press "Next".
                            </p>
                            <h4>6. Select Network and Address</h4>
                            <p>
                                First make sure at the top it says "ETH" for the Network, like this:
                            </p>
                            <p class="text-center">
                                <img src="../../../assets/images/screenshots/mew-eth-network.png">
                            </p>
                            <p>
                                In the list, select the address <span
                                class="wallet-address"></span>.
                            </p>
                            <p><strong>Note!</strong> You will need a small amount of Ether in your wallet. Make
                                sure you have 0.01 ETH or more.</p>
                            <p>
                                Check that you accept the terms, and press "Access My Wallet".
                            </p>
                            <h4>7. Add Custom ERC20 Token</h4>
                            <p>
                                Find the Tokens list box on the page, and click "+ Custom Tokens":
                            </p>
                            <p class="text-center">
                                <img src="../../../assets/images/screenshots/mew-custom-token.png">
                            </p>
                            <p>
                                Add a custom token with the address <span class="token-address"></span>, symbol
                                "<span
                                class="token-symbol"></span>" and decimals <span class="token-decimals"></span>.
                            </p>
                            <p class="text-center">
                                <img src="../../../assets/images/screenshots/mew-custom-token-form.png" alt="">
                            </p>
                            <p>Click "Save".</p>
                            <h4>8. Send Transaction</h4>
                            <p>
                                Click the "Send Transaction" button on the same page:
                            </p>
                            <p class="text-center">
                                <img src="../../../assets/images/screenshots/mew-send-transaction.png" width="180"
                                     alt="">
                            </p>
                            <p>In the form that opens:</p>
                            <ul>
                                <li>Select "<span class="token-symbol"></span>" for Type</li>
                                <li>Enter "1" for the Amount</li>
                                <li>In the To Address, paste the following address: <span
                                    class="verify-address"></span>
                                </li>
                            </ul>
                            <div class="note-box">
                                <p>
                                    If you see an error saying "Not enough Gas to send", that means your wallet
                                    doesn't have enough Ether to pay for the transaction. Make sure your wallet has
                                    at least 0.01 ETH.
                                </p>
                            </div>
                            <p>
                                Click "Send Transaction".
                            </p>
                            <div class="note-box">
                                <p>
                                    If you get an error indicating you need to "enable contract data", you need to
                                    set the Contract Data setting of your Ledger to "Yes".
                                </p>
                                <p>
                                    On your Ledger, navigate to the Ethereum app's settings, go to "Contract Data",
                                    and change it to "Yes".
                                </p>
                            </div>
                            <h4>9. Confirm Transaction In Ledger</h4>
                            <p>
                                Your Ledger will now ask you to confirm the transaction. Accept the transaction.
                            </p>
                            <h4>10. Confirm In MyEtherWallet</h4>
                            <p>
                                Lastly, MyEtherWallet will ask you to confirm the transfer of <span
                                class="token-symbol"></span>. Click "Confirm and Send".
                            </p>
                            <p><strong>Done!</strong> You will receive an email confirmation shortly after sending.
                            </p>
                        </div>
                        <div class="wallet-selection-content" data-wallet="metamask">
                            <a href="javascript: void 0;" class="back-to-selection">back to wallet selection</a>
                            <h3>MetaMask</h3>
                            <h4>1. Open MetaMask</h4>
                            <p>
                                Click the MetaMask icon <img
                                src="../../../assets/images/screenshots/metamask-button.png">
                                in your browser's toolbar.
                            </p>
                            <h4>2. Log In</h4>
                            <p>
                                At this point MetaMask may ask you to enter your password. Log in to continue.
                            </p>
                            <h4>3. Add Custom Token</h4>
                            <p>
                                Click the left menu button in MetaMask:
                            </p>
                            <p class="text-center">
                                <img src="../../../assets/images/screenshots/metamask-menu.png" alt="">
                            </p>
                            <p>
                                Scroll to the bottom of the menu to find the Add Token link:
                            </p>
                            <p class="text-center">
                                <img src="../../../assets/images/screenshots/metamask-custom-token.png" alt="">
                            </p>
                            <p>Click the "Add Token" link.</p>
                            <p>
                                Select the "Custom Token" tab and enter the address <span
                                class="token-address"></span>. When you enter the address, it should
                                automatically fill in the symbol "<span
                                class="token-symbol"></span>" and decimals <span class="token-decimals"></span>.
                            </p>
                            <p class="text-center">
                                <img src="../../../assets/images/screenshots/metamask-custom-token-form.png" alt="">
                            </p>
                            <p>Click "Next" and "Add Tokens".</p>
                            <h4>4. Send Transaction</h4>
                            <p class="text-center">
                                <img src="../../../assets/images/screenshots/metamask-send.png" alt="">
                            </p>
                            <p>
                                Copy the address <span
                                class="verify-address"></span> to clipboard, and open MetaMask again. In the
                                same menu click the bwKYC token and click the "Send" button.
                            </p>
                            <p>Paste the address you copied into the Add Recipient field.</p>
                            <p>Enter "1" for Amount, click "Send", and click "Confirm".</p>
                            <p>
                                <strong>That's it!</strong> You can now paste the address into the "Wallet Address"
                                field when Adding an External Wallet:
                            </p>
                            <p class="text-center">
                                <button class="btn btn-primary new-external" type="button">Add External Wallet
                                </button>
                            </p>
                        </div>
                        <div class="wallet-selection-content" data-wallet="mew">
                            <a href="javascript: void 0;" class="back-to-selection">back to wallet selection</a>
                            <h3>MyEtherWallet</h3>
                            <h4>1. Open MyEtherWallet</h4>
                            <p>
                                Open MyEtherWallet by going to
                                <a href="https://www.myetherwallet.com/access-my-wallet" target="_blank">
                                    myetherwallet.com/access-my-wallet
                                </a>
                            </p>
                            <h4>2. Access Your Wallet</h4>
                            <p>
                                Select the type of wallet you're using and go through the steps to access your
                                wallet.
                            </p>

                            <h4>3. Add Custom ERC20 Token</h4>
                            <p>
                                Find the Tokens list box on the page, and click "+ Custom Tokens":
                            </p>
                            <p class="text-center">
                                <img src="../../../assets/images/screenshots/mew-custom-token.png">
                            </p>
                            <p>
                                Add a custom token with the address <span class="token-address"></span>, symbol
                                "<span
                                class="token-symbol"></span>" and decimals <span class="token-decimals"></span>.
                            </p>
                            <p class="text-center">
                                <img src="../../../assets/images/screenshots/mew-custom-token-form.png" alt="">
                            </p>
                            <p>Click "Save".</p>
                            <h4>4. Send Transaction</h4>
                            <p>
                                Click the "Send Transaction" button on the same page:
                            </p>
                            <p class="text-center">
                                <img src="../../../assets/images/screenshots/mew-send-transaction.png" width="180"
                                     alt="">
                            </p>
                            <p>In the form that opens:</p>
                            <ul>
                                <li>Select "<span class="token-symbol"></span>" for Type</li>
                                <li>Enter "1" for the Amount</li>
                                <li>In the To Address, paste the following address: <span
                                    class="verify-address"></span>
                                </li>
                            </ul>
                            <p>
                                If you see an error saying "Not enough Gas to send", that means your wallet doesn't
                                have enough Ether to pay for the transaction. Make sure your wallet has at least
                                0.01 ETH.
                            </p>
                            <p>
                                Click "Send Transaction".
                            </p>
                            <h4>5. Confirm In MyEtherWallet</h4>
                            <p>
                                Lastly, MyEtherWallet will ask you to confirm the transfer of <span
                                class="token-symbol"></span>. Click "Confirm and Send".
                            </p>
                            <p><strong>Done!</strong> You will receive an email confirmation shortly after sending.
                            </p>
                        </div>
                        <div class="wallet-selection-content" data-wallet="mycrypto">
                            <a href="javascript: void 0;" class="back-to-selection">back to wallet selection</a>
                            <h3>MyCrypto</h3>
                            <h4>1. Open MyCrypto</h4>
                            <p>
                                Open MyCrypto by going to
                                <a href="https://mycrypto.com/account" target="_blank">
                                    mycrypto.com/account
                                </a>
                            </p>
                            <h4>2. Access Your Wallet</h4>
                            <p>
                                Select the type of wallet you're using and go through the steps to access your
                                wallet.
                            </p>
                            <h4>3. Add Custom Token</h4>
                            <p>
                                On the page, find the Token Balances box. If it just says "Scan For Tokens":
                            </p>
                            <p class="text-center">
                                <img src="../../../assets/images/screenshots/mycrypto-tokens.png">
                            </p>
                            <p>Click it. It will scan briefly and then show the button to Add Custom Token:</p>
                            <p class="text-center">
                                <img src="../../../assets/images/screenshots/mycrypto-custom-token.png">
                            </p>
                            <p>
                                Add a custom token with the address <span class="token-address"></span>. When you
                                enter the address, it should automatically fill in the symbol "<span
                                class="token-symbol"></span>" and decimals <span class="token-decimals"></span>.
                            </p>
                            <p class="text-center">
                                <img src="../../../assets/images/screenshots/mycrypto-custom-token-form.png" alt="">
                            </p>
                            <p>Click "Save".</p>
                            <h4>4. Send Transaction</h4>
                            <p>
                                At the top of the page find the Send Transaction form and enter:
                            </p>
                            <ul>
                                <li>In the To Address, paste the following address: <span
                                    class="verify-address"></span>
                                </li>
                                <li>Enter "1" for the Amount</li>
                                <li>Select "<span class="token-symbol"></span>" in the dropdown to the right of
                                    Amount
                                </li>
                            </ul>
                            <p>
                                If the Amount field is highlighted red and it doesn't let you send, that means your
                                wallet doesn't have enough Ether to pay for the transaction. Make sure your wallet
                                has at least 0.01 ETH.
                            </p>
                            <p>
                                Click "Send Transaction".
                            </p>
                            <h4>5. Confirm Transaction</h4>
                            <p>
                                Lastly, MyCrypto will ask you to confirm the transfer of <span
                                class="token-symbol"></span>. Click "Send".
                            </p>
                            <p><strong>Done!</strong> You will receive an email confirmation shortly after sending.
                            </p>
                        </div>
                        <div class="wallet-selection-content" data-wallet="other">
                            <a href="javascript: void 0;" class="back-to-selection">back to wallet selection</a>
                            <h3>Other Wallets</h3>
                            <p>
                                We don't have instructions for other wallets, but the rough steps are always the
                                same. If your wallet supports custom ERC20 tokens, you can follow these steps:
                            </p>
                            <h4>1. Add Custom ERC20 Token</h4>
                            <p>
                                Add a custom token with the address <span class="token-address"></span>, symbol
                                "<span
                                class="token-symbol"></span>" and decimals <span class="token-decimals"></span>.
                            </p>
                            <h4>2. Send 1 <span
                                class="token-symbol"></span></h4>
                            <p>
                                Send 1 <span class="token-symbol"></span> token to the following address:
                                <br>
                                <span class="verify-address"></span>
                            </p>
                            <p><strong>Done!</strong> You will receive an email confirmation shortly after sending.
                            </p>
                            <p>
                                If you'd like us to add instructions for other wallets, drop us an email at
                                <a href="mailto:blockwell@blockwell.ai">blockwell@blockwell.ai</a>
                                .
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    export default {
        name: 'ExternalInstructions'
    }
</script>