<template>
    <layout-default size="medium">

        <div class="back">
            <router-link to="/app/account"><i class="material-icons">chevron_left</i> Back to Account</router-link>
        </div>
        <div class="card">
            <div class="card-title">
                <h3>Chronica Notifications</h3>
            </div>
            <div class="card-content">
                <table class="table table-responsive-sm keys-table">
                    <thead>
                    <tr>
                        <th>
                            Main Account
                            <Info>This is the default account (aka. wallet) used by the Access Token.</Info>
                        </th>
                        <th>
                            Environment
                            <Info>The API Miner environment the Access Token can be used on.</Info>
                        </th>
                        <th>Date Created</th>
                    </tr>
                    </thead>
                    <tbody>
                    </tbody>
                </table>

                <div class="form-group">
                    <button class="btn btn-primary" type="button">Add Notification</button>
                </div>
            </div>
        </div>
        <Modal :show.sync="showTypes">
            <p>Select the type of notification to receive.</p>
            <div class="notification-types">
                <div class="notification-type">
                    <h4>Uniswap Price Change</h4>
                    <p>Notifies you when the price for a token changes on Uniswap.</p>
                </div>
            </div>
        </Modal>
        <Modal :show.sync="showForm">
            <a href="javascript: void 0;" @click="openTypes">&laquo; change listener type</a>
        </Modal>
    </layout-default>
</template>

<script>
import Clipboard from "@/components/Clipboard";
import Info from "@/components/Info";
import Modal from "@/components/Modal";
import ProgressBar from "@/components/ProgressBar";
import * as util from "@/assets/lib/util";
import ChronicaApi from "@/lib/api/ChronicaApi.ts";
import {mapGetters, mapState} from "vuex";

export default {
    components: {Modal, Info, Clipboard, ProgressBar},
    data() {
        return {
            showTypes: false,
            showForm: true
        };
    },
    computed: {
        chronica() {
            return this.$store.getters["user/chronica"];
        }
    },
    created() {
        this.chronica.matchers.all()
            .then(list => {
                console.dir(list);
            });
    },
    methods: {
        openTypes() {
            this.showForm = false;
            this.showTypes = true;
        }
    }
}
</script>

<style scoped>

</style>
