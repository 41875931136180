<template>
    <layout-default size="medium">

        <div class="back">
            <router-link to="/app/account"><i class="material-icons">chevron_left</i> Back to Account</router-link>
        </div>

        <div class="card">
            <div class="card-title">
                <h3>Emojicryption Settings</h3>
            </div>
            <div class="card-content">
                <p>
                    This page lets you configure your emojicryption alphabet for using
                    <router-link to="/qc">Quick Cipher</router-link>.
                </p>

                <h4>Token</h4>
                <p>
                    Anyone who knows which token you've selected here, and your email address, will be able
                    to use the alphabet you set below automatically.
                </p>
                <p v-if="contract" class="current-token">
                    <strong>Current token</strong>: {{ contract.name }}
                </p>
                <ContractLoader
                    label="Select New Token"
                    hide-recents
                    features="erc20"
                    :load-click="addressLoaded"
                />

                <h4>Alphabet</h4>

                <div class="form-group">
                    <div class="alphabet-wrapper">
                        <textarea class="form-control" name="alphabet" rows="1" autocapitalize="none"
                                  ref="alphabet"
                                  v-model="alphabet"/>

                        <div class="alphabet-emoji-icon">
                            <v-popover placement="auto">
                                <span class="material-icons">insert_emoticon</span>
                                <template #popover>
                                    <VEmojiPicker
                                        @select="emoji"
                                    />
                                </template>
                            </v-popover>
                        </div>
                    </div>
                    <div v-if="alphabetError" class="invalid-feedback">
                        {{ alphabetError }}
                    </div>
                    <small class="form-text text-muted">
                        Enter the alphabet for the cipher.
                    </small>
                </div>

                <h5>Preview Encryption</h5>
                <p>This shows how the sample text is encrypted using your configured alphabet.</p>
                <div class="form-group">
                    <label :for="$id('plaintext')">Plain Text</label>
                    <textarea class="form-control" readonly
                              :id="$id('plaintext')">{{ message }}</textarea>
                </div>
                <div class="form-group">
                    <label :for="$id('ciphertext')">Ciphertext</label>
                    <textarea class="form-control" readonly
                              :id="$id('ciphertext')">{{ encrypted }}</textarea>
                </div>
            </div>
        </div>
    </layout-default>
</template>

<script>
import {Emojicrypt} from "@/views/happs/book/encryption/Emojicrypt";
import * as R from "rambdax";
import runes from "runes";
import VEmojiPicker, {emojisDefault, categoriesDefault} from "v-emoji-picker";
import ContractLoader from "@/views/happs/components/ContractLoader";
import {mapGetters} from "vuex";

const crypt = new Emojicrypt();

export default {
    components: {ContractLoader, VEmojiPicker},
    data() {
        return {
            address: null,
            savedAddress: undefined,
            alphabet: `:!.,-_();😭🐑🐗🐧🎃👻🎄abcdefghijklmnopqrstuvwxyz0123456789`,
            loading: false,
            message: "This is some filler text that will be: encrypted!"
        };
    },
    methods: {
        addressLoaded(data) {
            this.address = data.address;
            this.api.saveEmojicryptionSettings({address: data.address})
                .catch(console.error);
        },
        resizeAlphabet() {
            this.$nextTick(() => {
                let el = this.$refs.alphabet;
                if (el) {
                    el.style = "height: auto;";
                    if (el.scrollHeight > 0) {
                        el.style = `height: ${el.scrollHeight + 5}px`;
                    }
                }
            });
        },
        emoji(emoji) {
            this.alphabet += emoji.data;
            this.input();
        },
        invalidAlphabet(input) {
            if (!input) {
                return 'An alphabet is required.';
            } else {
                let alphabet = runes(input.toLowerCase());
                let uniq = R.uniq(alphabet);
                if (uniq.length !== alphabet.length) {
                    return 'Alphabet must not have duplicate characters';
                }
            }
        }
    },
    computed: {
        ...mapGetters('user', ['api']),
        alphabetError() {
            return this.invalidAlphabet(this.alphabet);
        },
        encrypted() {
            if (this.alphabet && this.message) {
                let alphabet = runes(this.alphabet.toLowerCase());
                let password = runes('🤣izmbb81🔥f2️😭😠adgjmpsvy269?(-ah2h').filter(it => alphabet.includes(it));

                return crypt.encrypt(this.message, {
                    password: password.join(''),
                    alphabet: this.alphabet
                });
            }
            return '';
        }
    },
    asyncComputed: {
        contract() {
            if (this.address) {
                return this.api.loadContract(this.address)
                    .then(res => res[0]);
            }
            return null;
        }
    },
    watch: {
        alphabet(val) {
            this.resizeAlphabet();
            if (!this.loading && !this.invalidAlphabet(val)) {
                this.api.saveEmojicryptionSettings({alphabet: val})
                    .catch(console.error);
            }
        }
    },
    created() {
        this.loading = true;
        this.api.getEmojicryptionSettings()
            .then(res => {
                this.savedAddress = res.address || null;
                if (res.address) {
                    this.address = res.address;
                }
                if (res.alphabet) {
                    this.alphabet = res.alphabet;
                }
            })
            .finally(() => {
                this.loading = false;
            })
            .catch(console.error);
    }
}
</script>

<style scoped lang="scss">
@import "~@/assets/css/custom.scss";

.current-token {
    font-size: 20px;
}

.invalid-feedback {
    display: block;
}

.alphabet-wrapper {
    position: relative;

    ::v-deep( textarea ) {
        padding-right: 24px;
    }

    .alphabet-emoji-icon {
        position: absolute;
        font-size: 24px;
        right: 5px;
        top: 0;
        cursor: pointer;

        .material-icons {
            color: $dark;
        }
    }
}
</style>
