<template>
    <div class="modal fade" id="find-address-modal" tabindex="-1" role="dialog" aria-hidden="true">
        <div class="modal-dialog modal-lg modal-dialog-scrollable" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">Finding Your Wallet Address</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body wallet-selection-container">
                    <div class="wallet-selection-wrap">
                        <p>Start by selecting the wallet you're using.</p>
                        <div class="wallet-selection">
                            <div class="wallet-type-wrap">
                                <div class="wallet-type" data-wallet="trezor">
                                    <img src="../../../assets/images/logos/trezor.png" alt="Trezor">
                                </div>
                            </div>
                            <div class="wallet-type-wrap">
                                <div class="wallet-type" data-wallet="ledger">
                                    <img src="../../../assets/images/logos/ledger.svg" alt="Ledger">
                                </div>
                            </div>
                            <div class="wallet-type-wrap">
                                <div class="wallet-type" data-wallet="metamask">
                                    <img src="../../../assets/images/logos/metamask.png" alt="MetaMask">
                                </div>
                            </div>
                            <div class="wallet-type-wrap">
                                <div class="wallet-type" data-wallet="mew">
                                    <img src="../../../assets/images/logos/mew.png" alt="MyEtherWallet">
                                </div>
                            </div>
                            <div class="wallet-type-wrap">
                                <div class="wallet-type" data-wallet="mycrypto">
                                    <img src="../../../assets/images/logos/mycrypto.svg" alt="MyCrypto">
                                </div>
                            </div>
                            <div class="wallet-type-wrap">
                                <div class="wallet-type" data-wallet="other">
                                    <div class="wallet-name">Other</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="wallet-selection-content" data-wallet="trezor">
                        <a href="javascript: void 0;" class="back-to-selection">back to wallet selection</a>
                        <h3>Trezor</h3>
                        <p>
                            Trezor's own software does not support ERC20 tokens, but they have a partnership with
                            MyEtherWallet that lets you use your Trezor for any token. To find your address, follow
                            these steps.
                        </p>
                        <h4>1. Plug Trezor In To Your Computer</h4>
                        <p>
                            Plug Trezor into your computer using its USB cable.
                        </p>
                        <h4>2. Open MyEtherWallet</h4>
                        <p>
                            Open MyEtherWallet by going to
                            <a href="https://www.myetherwallet.com/access-my-wallet" target="_blank">
                                myetherwallet.com/access-my-wallet
                            </a>
                        </p>
                        <h4>3. Access Hardware Wallet</h4>
                        <p>
                            Select the "Hardware" option for access.
                        </p>
                        <p class="text-center">
                            <img src="../../../assets/images/screenshots/mew-hardware.png" width="150" alt="">
                        </p>
                        <h4>4. Select Trezor</h4>
                        <p>In the list select Trezor and press "Continue".</p>
                        <h4>5. Install Bridge If Asked</h4>
                        <p>
                            At this point Trezor may ask you to install the Trezor Bridge. Follow their
                            instructions, and once installed go back to Step 1.
                        </p>
                        <h4>6. Allow MyEtherWallet To Read</h4>
                        <p>
                            Trezor will ask you to give permission for MyEtherWallet to "Read public keys from
                            Trezor device". Click "Allow once for this session", or check the "Don't ask me again"
                            checkbox and then click "Always allow for this service".
                        </p>
                        <h4>7. Export Ethereum public key</h4>
                        <p>
                            Next, Trezor will ask you to Export your Ethereum public key. This allows MyEtherWallet
                            to see your wallet address.
                        </p>
                        <h4>8. Select Network and Address</h4>
                        <p>
                            First make sure at the top it says "ETH" for the Network, like this:
                        </p>
                        <p class="text-center">
                            <img src="../../../assets/images/screenshots/mew-eth-network.png">
                        </p>
                        <p>
                            Then click the address you want to use in the list. If you're unsure, just use the first
                            one in the list.
                        </p>
                        <p><strong>Note!</strong> You will need a small amount of Ether in your wallet. Make sure
                            you have 0.01 ETH or more.</p>
                        <p>
                            Check that you accept the terms, and press "Access My Wallet".
                        </p>
                        <h4>9. Copy Your Wallet Address</h4>
                        <p>
                            Your wallet is now open, and you can copy the address by clicking the copy button:
                        </p>
                        <p class="text-center">
                            <img src="../../../assets/images/screenshots/mew-copy-address.png">
                        </p>
                        <p>
                            <strong>That's it!</strong> You can now paste the address into the "Wallet Address"
                            field when Adding an External Wallet:
                        </p>
                        <p>
                            <strong>Note!</strong> You should keep MyEtherWallet open, as it will be used again in
                            the verification step after you add your wallet.
                        </p>
                        <p class="text-center">
                            <button class="btn btn-primary new-external" type="button">Add External Wallet
                            </button>
                        </p>
                    </div>
                    <div class="wallet-selection-content" data-wallet="ledger">
                        <a href="javascript: void 0;" class="back-to-selection">back to wallet selection</a>
                        <h3>Ledger</h3>
                        <p>
                            Ledger's own software does not support ERC20 tokens, but they have a partnership with
                            MyEtherWallet that lets you use your Ledger for any token. To find your address, follow
                            these steps.
                        </p>
                        <h4>1. Plug Ledger In To Your Computer</h4>
                        <p>
                            Plug your Ledger in to your computer using its USB cable, unlock it, and open the
                            Ethereum app.
                        </p>
                        <h4>2. Open MyEtherWallet</h4>
                        <p>
                            Open MyEtherWallet by going to
                            <a href="https://www.myetherwallet.com/access-my-wallet" target="_blank">
                                myetherwallet.com/access-my-wallet
                            </a>
                        </p>
                        <h4>3. Access Hardware Wallet</h4>
                        <p>
                            Select the "Hardware" option for access.
                        </p>
                        <p class="text-center">
                            <img src="../../../assets/images/screenshots/mew-hardware.png" width="150" alt="">
                        </p>
                        <h4>4. Select Ledger</h4>
                        <p>In the list select Ledger and press "Continue".</p>
                        <h4>5. Choose Ledger App</h4>
                        <p>
                            Choose which Ledger app you normally use. If you usually use Ledger Live, select that.
                            Otherwise, keep the selection on just "Ethereum".
                        </p>
                        <p class="text-center">
                            <img src="../../../assets/images/screenshots/mew-choose-app.png" width="400" alt="">
                        </p>
                        <p>
                            Then press "Next".
                        </p>
                        <h4>6. Select Network and Address</h4>
                        <p>
                            First make sure at the top it says "ETH" for the Network, like this:
                        </p>
                        <p class="text-center">
                            <img src="../../../assets/images/screenshots/mew-eth-network.png">
                        </p>
                        <p>
                            Then click the address you want to use in the list. If you're unsure, just use the first
                            one in the list.
                        </p>
                        <p><strong>Note!</strong> You will need a small amount of Ether in your wallet. Make sure
                            you have 0.01 ETH or more.</p>
                        <p>
                            Check that you accept the terms, and press "Access My Wallet".
                        </p>
                        <h4>8. Copy Your Wallet Address</h4>
                        <p>
                            Your wallet is now open, and you can copy the address by clicking the copy button:
                        </p>
                        <p class="text-center">
                            <img src="../../../assets/images/screenshots/mew-copy-address.png">
                        </p>
                        <p>
                            <strong>That's it!</strong> You can now paste the address into the "Wallet Address"
                            field when Adding an External Wallet.
                        </p>
                        <p>
                            <strong>Note!</strong> You should keep MyEtherWallet open, as it will be used again in
                            the verification step after you add your wallet.
                        </p>
                        <p class="text-center">
                            <button class="btn btn-primary new-external" type="button">Add External Wallet
                            </button>
                        </p>
                    </div>
                    <div class="wallet-selection-content" data-wallet="metamask">
                        <a href="javascript: void 0;" class="back-to-selection">back to wallet selection</a>
                        <h3>MetaMask</h3>
                        <h4>1. Open MetaMask</h4>
                        <p>
                            Click the MetaMask icon <img
                            src="../../../assets/images/screenshots/metamask-button.png"> in
                            your browser's toolbar.
                        </p>
                        <h4>2. Log In</h4>
                        <p>
                            At this point MetaMask may ask you to enter your password. Log in to continue.
                        </p>
                        <h4>3. Copy Address</h4>
                        <p>
                            At the top of the MetaMask window, click where it displays your account name and
                            address:
                        </p>
                        <p class="text-center">
                            <img src="../../../assets/images/screenshots/metamask-copy-address.png" alt="">
                        </p>
                        <p>
                            <strong>That's it!</strong> You can now paste the address into the "Wallet Address"
                            field when Adding an External Wallet:
                        </p>
                        <p class="text-center">
                            <button class="btn btn-primary new-external" type="button">Add External Wallet
                            </button>
                        </p>
                    </div>
                    <div class="wallet-selection-content" data-wallet="mew">
                        <a href="javascript: void 0;" class="back-to-selection">back to wallet selection</a>
                        <h3>MyEtherWallet</h3>
                        <h4>1. Open MyEtherWallet</h4>
                        <p>
                            Open MyEtherWallet by going to
                            <a href="https://www.myetherwallet.com/access-my-wallet" target="_blank">
                                myetherwallet.com/access-my-wallet
                            </a>
                        </p>
                        <h4>2. Access Your Wallet</h4>
                        <p>
                            Select the type of wallet you're using and go through the steps to access your wallet.
                        </p>
                        <h4>3. Copy Your Wallet Address</h4>
                        <p>
                            Your wallet is now open, and you can copy the address by clicking the copy button:
                        </p>
                        <p class="text-center">
                            <img src="../../../assets/images/screenshots/mew-copy-address.png">
                        </p>
                        <p>
                            <strong>That's it!</strong> You can now paste the address into the "Wallet Address"
                            field when Adding an External Wallet:
                        </p>
                        <p>
                            <strong>Note!</strong> You should keep MyEtherWallet open, as it will be used again in
                            the verification step after you add your wallet.
                        </p>
                        <p class="text-center">
                            <button class="btn btn-primary new-external" type="button">Add External Wallet
                            </button>
                        </p>
                    </div>
                    <div class="wallet-selection-content" data-wallet="mycrypto">
                        <a href="javascript: void 0;" class="back-to-selection">back to wallet selection</a>
                        <h3>MyCrypto</h3>
                        <h4>1. Open MyCrypto</h4>
                        <p>
                            Open MyCrypto by going to
                            <a href="https://mycrypto.com/account" target="_blank">
                                mycrypto.com/account
                            </a>
                        </p>
                        <h4>2. Access Your Wallet</h4>
                        <p>
                            Select the type of wallet you're using and go through the steps to access your wallet.
                        </p>
                        <h4>3. Copy Your Wallet Address</h4>
                        <p>
                            Your wallet is now open, and you can copy the address by clicking "copy address":
                        </p>
                        <p class="text-center">
                            <img src="../../../assets/images/screenshots/mycrypto-copy-address.png">
                        </p>
                        <p>
                            <strong>That's it!</strong> You can now paste the address into the "Wallet Address"
                            field when Adding an External Wallet:
                        </p>
                        <p>
                            <strong>Note!</strong> You should keep MyCrypto open, as it will be used again in the
                            verification step after you add your wallet.
                        </p>
                        <p class="text-center">
                            <button class="btn btn-primary new-external" type="button">Add External Wallet
                            </button>
                        </p>
                    </div>
                    <div class="wallet-selection-content" data-wallet="other">
                        <a href="javascript: void 0;" class="back-to-selection">back to wallet selection</a>
                        <h3>Other Wallets</h3>
                        <p>
                            We don't provide instructions for other wallets at this time.
                        </p>
                        <p>
                            If you'd like us to add instructions for other wallets, drop us an email at
                            <a href="mailto:blockwell@blockwell.ai">blockwell@blockwell.ai</a>
                            .
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    export default {
        name: 'ExternalFindWallet'
    }
</script>