<template>
    <layout-default>
        <div class="back">
            <router-link to="/app/account"><i class="material-icons">chevron_left</i> Back to Account</router-link>
        </div>
        <h1 class="kyc-title">KYC</h1>
        <p class="kyc-incomplete">
            KYC has three parts, finish each one below to complete your KYC.
        </p>

        <div v-if="completed" class="kyc-completed">
            <p>
                The following table shows all the organizations who have access to your KYC data. New organizations can
                only gain access to your KYC information with your explicit permission.
            </p>
            <table class="table table-responsive-sm organizations-table">
                <thead>
                <tr>
                    <th>
                        Organization
                        <Info>The name of the organization requesting KYC information.</Info>
                    </th>
                    <th>
                        Status
                    </th>
                    <th>
                        Links
                    </th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="org of organizations">
                    <td class="org-name">
                        {{ org.displayName }}
                    </td>
                    <td class="org-status">
                        {{ statusName(org.status) }}
                    </td>
                    <td class="org-links">
                        <a v-if="org.status === 'pending'" href="javascript: void 0;" class="view-request"
                           @click="showRequest(org)">View Pending Request</a>
                        <a v-if="org.status === 'denied'" href="javascript: void 0;" class="view-request"
                           @click="showRequest(org)">Change Response</a>
                    </td>
                </tr>
                </tbody>
            </table>
        </div>

        <div class="kyc-completed" style="display: none;">
            <p class="big">
                You have completed KYC.
            </p>
            <p>
                The organizations listed above as "Completed" now have access to your KYC information.
            </p>
        </div>

        <div class="row align-items-stretch mb-4">
            <div class="col-md-4">
                <div class="kyc-box kyc-email">
                    <div class="icon-wrap">
                        <div class="material-icons">email</div>
                        <div class="verified checkmark material-icons">check_circle</div>
                    </div>
                    <h4>
                        Verify email address
                    </h4>
                    <div class="unverified">
                        <p>
                            You must verify your email address for KYC.
                        </p>
                        <p>
                            <VerifyEmail>Verify Email</VerifyEmail>
                        </p>
                    </div>
                    <div class="verified">
                        <p>
                            Email address verified.
                        </p>
                    </div>
                </div>
            </div>
            <div class="col-md-4">
                <div class="kyc-box kyc-account verified">
                    <div class="icon-wrap">
                        <div class="material-icons">account_balance_wallet</div>
                        <div class="verified checkmark material-icons">check_circle</div>
                    </div>
                    <h4>
                        Verify wallet
                    </h4>
                    <div class="unverified">
                        <p>
                            Your Blockwell Wallet is verified automatically.
                        </p>
                    </div>
                    <div class="verified">
                        <p>
                            Your Blockwell Wallet is verified automatically.
                        </p>
                        <p>
                            If you want to use a different wallet, add it to your
                            <router-link to="/app/account/external">External Wallets</router-link>.
                        </p>
                    </div>
                </div>
            </div>
            <div class="col-md-4">
                <div class="kyc-box kyc-details">
                    <div class="icon-wrap">
                        <div class="material-icons">insert_drive_file</div>
                        <div class="verified checkmark material-icons">check_circle</div>
                    </div>
                    <h4>
                        Submit details
                    </h4>
                    <div class="unverified">
                        <p>
                            We will need your name and country of residence to complete KYC.
                        </p>
                        <p>
                            <router-link to="/app/account/kyc/details" class="btn btn-primary">Submit Details
                            </router-link>
                        </p>
                    </div>
                    <div class="verified">
                        <p>
                            Details submitted.
                        </p>
                    </div>
                </div>
            </div>
        </div>

        <div class="kyc-completed form-group" style="display: none;">
            <router-link to="/app/account" class="btn btn-primary">Back to Account</router-link>
        </div>

        <div class="modal fade" id="request-modal" tabindex="-1" role="dialog" aria-hidden="true">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="exampleModalLabel">KYC Information Request</h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <p>
                            <strong class="org-name"></strong> is requesting access to your KYC information. This
                            includes everything you submitted for your original KYC:
                        </p>
                        <ul>
                            <li>Email Address</li>
                            <li>Blockwell Wallet Address</li>
                            <li>External Wallet Addresses</li>
                            <li>First and Last Name</li>
                            <li>Country of Residence</li>
                            <li>Language Preference</li>
                        </ul>
                        <p>
                            If you allow access, <span class="org-name"></span> will receive the above information for
                            their KYC process.
                        </p>
                        <p class="error"></p>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-outline-danger deny">Deny</button>
                        <button type="button" class="btn btn-primary allow">Allow</button>
                    </div>
                </div>
            </div>
        </div>
    </layout-default>
</template>

<script>
import * as util from '@/assets/lib/util';
import Api from '@/lib/api/WalletApi';
import Info from "@/components/Info";
import VerifyEmail from "@/components/VerifyEmail";
import * as filter from "secure-filters";
import 'bootstrap';
import * as bootbox from 'bootbox';

const api = new Api('legacy');
let token;
let interval;

export default {
    components: {
        Info,
        VerifyEmail
    },
    data() {
        return {
            organizations: null,
            completed: false
        }
    },
    mounted() {
        let urlParams = new URLSearchParams(window.location.search);
        let org = urlParams.get('o');

        if (org) {
            api.addOrganization(org)
                .then(() => {
                    return this.start();
                })
                .catch(err => {
                    if (err.response && err.response.status === 404) {
                        bootbox.alert(util.errorText(`Could not find an organization with the name "${org}", please double check your link.`));
                    } else {
                        bootbox.alert(util.errorText(err));
                    }
                    return this.start();
                });
        } else {
            this.start();
        }
    },
    methods: {

        start() {
            interval = setInterval(() => {
                this.refresh();
            }, 5000);

            return this.refresh();
        },

        refresh() {
            return api.getKycStatus()
                .then(res => {
                    return this.updateStatus(res);
                })
                .catch(err => {
                    console.error(err);
                });
        },

        async updateStatus(status) {
            this.completed = status.completed === "yes";
            if (status.completed === "yes") {
                $('.kyc-title').text('KYC');
                $('.kyc-completed').show();
                $('.kyc-incomplete').hide();
                this.organizations = status.organizations;
                clearInterval(interval);
            } else if (status.organizations && status.organizations.length === 1) {
                let org = status.organizations[0];
                if (org.name !== "blockwell") {
                    $('.kyc-title').text(`KYC for ${org.displayName}`);
                }
            }

            if (status.emailVerified) {
                $('.kyc-email').removeClass('unverified').addClass('verified');
            } else {
                $('.kyc-email').removeClass('verified').addClass('unverified');
            }

            $('.kyc-account').removeClass('unverified').addClass('verified');

            if (status.completed === "denied") {
                $('.kyc-details').removeClass('unverified').removeClass('verified').addClass('denied')
                    .append(`<p>${filter.html(status.denied)}</p>`);
                clearInterval(interval);
            } else {
                if (status.detailsSubmitted) {
                    $('.kyc-details').removeClass('unverified').addClass('verified');
                } else {
                    $('.kyc-details').removeClass('verified').addClass('unverified');
                }
            }
        },

        showRequest(org) {
            let $modal = $('#request-modal');

            $modal.find('.org-name').text(org.displayName);
            let $deny = $modal.find('.deny');
            let $allow = $modal.find('.allow');
            $deny.attr('disabled', false);
            $allow.attr('disabled', false);

            $deny.unbind('click').click(() => {
                $deny.attr('disabled', true);
                $allow.attr('disabled', true);
                api.denyOrganization(org.name)
                    .then(res => {
                        $modal.modal('hide');
                        return this.refresh();
                    })
                    .catch(err => {
                        let message = util.errorText(err);
                        $modal.find('.error').text(message);
                        $deny.attr('disabled', false);
                        $allow.attr('disabled', false);
                    });

                return false;
            });

            $allow.unbind('click').click(() => {
                $deny.attr('disabled', true);
                $allow.attr('disabled', true);
                api.allowOrganization(org.name)
                    .then(res => {
                        $modal.modal('hide');
                        return this.refresh();
                    })
                    .catch(err => {
                        let message = util.errorText(err);
                        $modal.find('.error').text(message);
                        $deny.attr('disabled', false);
                        $allow.attr('disabled', false);
                    });

                return false;
            });

            $modal.find('.error').empty();

            $modal.modal();
        },

        statusName(status) {
            switch (status) {
                case 'pending':
                    return 'Pending';
                case 'denied':
                    return 'Denied';
                default:
                    return 'Completed';
            }
        }
    }
}

</script>
