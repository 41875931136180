<template>
    <layout-default>
        <portal to="title">
            <span>My Contracts</span>
        </portal>
        <p>Below is a list of contracts you've created using Blockwell's deployers.</p>
        <div class="table-responsive-md">
            <table class="table deployed-contracts-table">
                <thead>
                <tr>
                    <th>Name</th>
                    <th>Links</th>
                    <th>Address</th>
                    <th>Network</th>
                    <th>Type</th>
                    <th>Created</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="it in list">
                    <td>{{ it.name }}</td>
                    <td>
                        <router-link v-if="it.happ" :to="it.happ">happ</router-link>
                    </td>
                    <td class="ellipsize-address">
                        <Clipboard :address="it.address" :network="it.network" />
                    </td>
                    <td>{{ networkName(it.network) }}</td>
                    <td>{{ it.type }}</td>
                    <td class="date-column">{{ date(it.created) }}</td>
                </tr>
                </tbody>
            </table>
        </div>
    </layout-default>
</template>

<script>
import { getNetwork } from "@/assets/lib/networks";
import Clipboard from "@/components/Clipboard";
import moment from "moment";
import { mapGetters } from "vuex";

export default {
    components: { Clipboard },
    data() {
        return {
            list: []
        };
    },
    computed: {
        ...mapGetters("user", ["api"])
    },
    methods: {
        networkName(network) {
            return getNetwork(network).networkName;
        },
        date(created) {
            return moment(created).format("ll");
        }
    },
    created() {
        this.api.getDeployedHistory()
            .then(it => this.list = it);
    }
};
</script>

<style scoped lang="scss">
.ellipsize-address {
    font-size: 0.8em;
    white-space: nowrap;
}

.deployed-contracts-table {
    @media screen and (max-width: 540px) {
        font-size: 0.9em;
    }
}

.date-column {
    white-space: nowrap;
}
</style>
