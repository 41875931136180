<template>
    <button class="btn btn-primary" @click="verify" :disabled="loading">
        <slot></slot>
    </button>
</template>

<script>
    import emailVerificationTemplate from '@/assets/templates/verify-email.hbs';
    import * as bootbox from 'bootbox';
    import {mapGetters} from "vuex";

    export default {
        data() {
            return {
                loading: false
            };
        },
        computed: {
            ...mapGetters('user', ['api'])
        },
        methods: {
            verify() {
                this.loading = true;

                this.api.verifyEmail()
                    .then(res => {
                        if (res.status === "already_verified") {
                            bootbox.alert({
                                message: "Email address already verified.",
                                callback: () => {
                                    this.loading = false;
                                }
                            });
                        } else {
                            bootbox.alert({
                                message: emailVerificationTemplate(),
                                callback: () => {
                                    this.loading = false;
                                }
                            });
                        }
                    });

                return false;
            }
        }
    }
</script>
