<template>
    <layout-default size="medium">

        <div class="back">
            <router-link to="/app/account"><i class="material-icons">chevron_left</i> Back to Account</router-link>
        </div>
        <div class="card">
            <div class="card-title">
                <h3>API Miner Access Tokens</h3>
            </div>
            <div class="card-content">
                <p>
                    On this page you can create Access Tokens for <a
                    href="https://docs.blockwell.ai/apiminer" target="_blank">API Miner</a>.
                </p>
                <p>When creating it, <strong>the Access Token will only be shown once</strong>, so
                    save it in a secure location.</p>

                <table class="table table-responsive-sm keys-table">
                    <thead>
                    <tr>
                        <th>
                            Main Account <Info>This is the default account (aka. wallet) used by the Access Token.</Info>
                        </th>
                        <th>
                            Environment <Info>The API Miner environment the Access Token can be used on.</Info>
                        </th>
                        <th>Date Created</th>
                    </tr>
                    </thead>
                    <tbody>
                    <template v-if="tokens.length > 0">
                        <tr v-for="token in tokens">
                            <td>{{token.account}}</td>
                            <td>apiminer.blockwell.ai</td>
                            <td>{{token.meta.created.slice(0, 10)}}</td>
                        </tr>
                    </template>
                    <tr v-else>
                        <td colspan="3">
                            No Access Tokens found. Click the button below to create one.
                        </td>
                    </tr>
                    </tbody>
                </table>

                <div class="form-group">
                    <button class="btn btn-primary new-key" type="button" @click="create">New Access Token</button>
                </div>
            </div>
        </div>

        <div class="modal fade" id="new-key-modal" tabindex="-1" role="dialog" aria-hidden="true">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="exampleModalLabel">New API Key</h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <ProgressBar v-if="loading"/>
                        <p v-else-if="error" class="error">{{error}}</p>
                        <div v-else>
                            <p>Key created!</p>
                            <p>
                                <strong>This is the only time the key will be shown. Save it to a secure location.</strong>
                            </p>
                            <div class="markdown-body">
                                <p>API Key</p>
                                <Clipboard element="pre" container="new-key-modal" button :clipboard="key"/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </layout-default>
</template>

<script>
    import Clipboard from "@/components/Clipboard";
    import Info from "@/components/Info";
    import ProgressBar from "@/components/ProgressBar";
    import * as util from "@/assets/lib/util";
    import {mapGetters} from "vuex";

    export default {
        components: {Info, Clipboard, ProgressBar},
        data() {
            return {
                tokens: [],
                loading: false,
                key: "",
                error: null
            };
        },
        computed: {
            ...mapGetters('user', ['api']),
        },
        created() {
            this.refresh();
        },
        methods: {
            refresh() {
                this.api.getApiKeys()
                    .then((keys) => {
                        this.tokens = keys;
                    })
                    .catch(console.error);
            },
            create() {
                this.loading = true;
                let $modal = $('#new-key-modal');
                $modal.modal({
                    backdrop: 'static'
                });

                this.api.createApiKey()
                    .then((key) => {
                        this.loading = false;
                        this.key = key.auth;
                        return this.refresh();
                    })
                    .catch(err => {
                        this.loading = false;
                        this.error = util.errorText(err);
                    })
            }
        }
    }
</script>

<style scoped>

</style>
