import SubmissionsList from "@/components/dumbapp/SubmissionsList.vue";
import Modal from "@/components/Modal.vue";
import Pagination from "@/components/Pagination.vue";
import TutorialMixin from "@/lib/TutorialMixin";
import moment from "moment";
import { mapActions } from "vuex";
import Vue from "vue";
export default Vue.extend({
    components: { Modal, SubmissionsList, Pagination },
    mixins: [TutorialMixin],
    data() {
        return {
            sorting: "-meta.created",
            pageSize: 10,
            showTutorialFinished: false,
            result: {
                count: 0,
                data: [],
            },
        };
    },
    computed: {
        api() {
            return this.$store.getters["user/api"];
        },
        loggedIn() {
            return this.$store.getters["user/loggedIn"];
        },
        submissions() {
            return this.$store.getters["dumbapp/submissions"];
        },
        recentSubmissions() {
            return this.$store.getters["dumbapp/recentSubmissions"];
        },
        pages() {
            return Math.ceil(this.result.count / this.pageSize);
        },
        count() {
            return this.result.count;
        },
        query() {
            return {
                sort: this.sorting,
                pageSize: this.pageSize,
                page: this.page,
            };
        },
        list() {
            let list = [];
            if (this.loggedIn) {
                let result = [...this.result.data];
                // If it's the first page, we want to prioritize the recent submissions
                // because they will get updated with state changes
                if (this.page === 1) {
                    let recent = [...this.recentSubmissions];
                    if (result.length > 0) {
                        let first = result[0];
                        let index = recent.findIndex(it => it.id === first.id);
                        if (index > 0) {
                            result = [...recent.splice(0, index), ...result];
                        }
                        while (recent.length > 0) {
                            let it = recent.shift();
                            if (result[index].id === it.id) {
                                result[index] = it;
                            }
                            else {
                                result.splice(index, 0, it);
                            }
                            ++index;
                        }
                    }
                    else {
                        result = recent;
                    }
                }
                for (let it of result) {
                    list.push(it);
                }
            }
            else {
                list = Object.values(this.submissions);
                list.sort((a, b) => {
                    return moment(b?.data?.created).diff(a?.data?.created);
                });
            }
            return list;
        },
        page: {
            get() {
                let page = this.$route.query.page;
                return page ? parseInt(page) : 1;
            },
            set(val) {
                let query = {};
                if (val > 1) {
                    query.page = val.toString();
                }
                this.$router.push({ query });
            },
        },
    },
    watch: {
        query: {
            deep: true,
            immediate: true,
            handler(query) {
                const api = this.api;
                if (this.loggedIn) {
                    api
                        .getSubmissionHistory(query)
                        .then(async (res) => {
                        for (let it of res.data) {
                            if (it.data.pending()) {
                                this.loadSubmission(it.id);
                            }
                        }
                        this.result = res;
                    })
                        .catch(console.error);
                }
            },
        },
    },
    methods: {
        ...mapActions("dumbapp", ["loadSubmission"]),
        clearFilter() {
            this.filterValue = "";
        },
        rewardShown() {
            console.log("rewardShown");
            this.tutorialRewardShown("dumbapp");
        },
    },
    mounted() {
        this.api.getTutorialState("dumbapp").then((it) => {
            if (it?.rewarded && !it.rewardShown) {
                this.showTutorialFinished = true;
            }
        });
    },
});
